.slideshow-container {
  max-width: 800px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  img {
    width: auto;
    height: 50vh;
  }
  div.p4 {
    width: 100%;
    height: 10vh;
  }
}

.slide {
  display: none;
}

.active {
  display:contents;
  background-color: #717171;
}

.navigation-buttons {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width: 100vh;
  align-self: center;
}

.switch-buttons {
    button {
      background-color: #1e1e1e;
      border: none;
      border-radius: 30px;
      padding: 20px;
      color: white;
      cursor: pointer;
      font-size: 20px;
    }
    display: flex;
    flex-direction: row;
    column-gap: 1vh;
    align-self: center;
  }
  
  .switch-buttons button:hover {
    background-color: #bbb;
  }

  .reset-buttons {
    button {
      border: none;
      padding: 10px;
      color: #1e1e1e;
      cursor: pointer;
      font-size: 20px;
      background-color: transparent;
    }
  }
  
  .reset-buttons button:hover {
    color: #bbb;
  }
  
