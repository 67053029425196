.image-container {
    position: relative;
    width: 100%;
    height: 40vh;
    overflow: hidden;
  }
  
  .fade-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
  }
  