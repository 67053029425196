.CircleGraph {
    position: relative;
}

.circles {
    position: relative;
    height: 90vh;
    width: 90vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 4vh;

}

.circles > .gradient {
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #a8b9ff 0%, rgba(255, 255, 255, 0) 100%);
    height: 90vh;
    width: 90vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.circles > img {
    position: absolute;
    align-self: center;
}

@keyframes circleRotation {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.circles > img:nth-of-type(1) {
    scale: 0.9;

    animation: circleRotation 7s ease-in-out infinite;
    animation-delay: 0ms; /* same as transition delay */
}

.circles > img:nth-of-type(2) {
    scale: 0.925;

    animation: circleRotation 10s ease-in-out infinite;
    animation-delay: 40ms; /* same as transition delay */
}

.circles > img:nth-of-type(3) {
    scale: 0.95;

    animation: circleRotation 7s ease-in-out infinite;
    animation-delay: 80ms; /* same as transition delay */
}

.circles > img:nth-of-type(4) {
    scale: 0.975;

    animation: circleRotation 10s ease-in-out infinite;
    animation-delay: 80ms; /* same as transition delay */
}

.labels {
    position: absolute;
    height: 50vh;
    width: 50vh;
    border-radius: 100%;
}

.number-label {
    position: absolute;
    width: 5vh;
    height: 5vh;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F8E9E9;

    background: #1E1E1E;
}

.labels > div {
    position: absolute;
    top: 65%;
    left: 15%;
}

.labels > div:nth-of-type(2) {
    top: 30%;
    left: 80%;
}

.labels > div:nth-of-type(1) {
    top: 10%;
    left: 10%;
}