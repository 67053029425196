$gray: radial-gradient(58.56% 54.51% at 36.71% 65.78%, rgba(255, 255, 255, 0.88) 0%, #F1F1F1 100%);
$dark: #1E1E1E;

.front-section {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    align-items: center;
    justify-content: center;
}

.header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
}

.front-section > .header {
    width: 14ch;
    animation: typing 2s steps(14), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    // font-size: 2.6rem;
}

@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.header-introduction {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 50%;
    text-align: center;
    font-size: 30px;
    color: lightgrey;
}

.front-section > * {
    position: sticky;
    top: 50px;
}

.front-section > .header-introduction {
    position: sticky;
    top: 150px;
}

.header2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    font-family: 'Inter';
    text-align: left;
    width: 41vh;
}

.personal-links-button-row {
    display: flex;
    position: sticky;
    top: 250px;
}

.personal-links-button-row > .button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
}

.github, .linkedin, .resume {
    background-color: $dark;
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: $dark;
}

.circular-button {
    right: 10vh;
    bottom: 10vh;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: fixed;
    background-color: $dark;
    color: #fff;
    font-size: 60px;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    &:hover {
        background-color: crimson; /* Change background color on hover */
    }
}

.circular-button-howdy {
    right: 20vh;
    bottom: 10vh;
    width: 100px;
    height: 100px;
    color: $dark;
    position: fixed;
    font-size: 20px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

// About section
.about-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10vh 30vh 10vh 15vh;
    padding: 20px;
    background-color: whitesmoke;
    border-left: $dark 1px solid;
}

.about-text {
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    font-size: 16px;
    width: 45%;
}

.about-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3vh;
    height: 30vh;
    width: 30vh;
    overflow: hidden;
    border-radius: 100vh;
    &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


// Projects section

.projects-section {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100vh;
    background-color: white;
    scroll-snap-align: start;
    overflow: auto;
}

.projects-text {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    row-gap: 4vh;
    width: 45%;
    padding-left: 15vh;
}

.projects-display {
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    align-items: center;
    justify-content: center;
}

// Experience section

.experience-section {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 130vh;
    background-color: white;
    scroll-snap-align: start;
    overflow: auto;
}

.references-container {
    display: flex;
    flex-direction: column;
    row-gap: 4vh;
}

.references-container > .reference {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border: 1px solid $dark;
    border-radius: 30px 80px 30px 80px;
    max-width: 500px;
    padding: 40px;
}

.references-container > .reference > .reference-text {
    font-size: 14px;
}

.references-container > .reference > .reference-header-text {
    font-size: 18px;
    font-weight: 600;
    color: gray;
}