$gray: radial-gradient(58.56% 54.51% at 36.71% 65.78%, rgba(255, 255, 255, 0.956) 0%, #f1f1f174 100%);
$dark: #1E1E1E;
$purple: linear-gradient(180deg, #000000 0%, #040028c5 100%);
$blue: linear-gradient(180deg, #1E1E1E 0%, #006aff 100%);

// Common components
.section-tb {
    display: flex;
    flex-direction: column;
    padding: 5vh 15vh 5vh 15vh;
    background-color: white
}

.white-gradient-bg {
    background: white;
    border-radius: 80px 100px 100px 100px;
    padding: 5vh;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
}

.white-bg {
    background: white;
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 80px 100px 100px 100px;
    padding: 5vh;  
}

.p1-tb {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 2.2vh;
    line-height: 3.5vh;

    color: white;
    padding: 2vh;
}

// white header
.p2-tb {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 6vh;
    color: white;
    text-align: center;
}

// purple gradient header
.p3-tb {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 8vh;
    text-align: left;

    color: white;
}

// regular black paragraph
.p4-tb {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 2.2vh;
    line-height: 3.5vh;

    color: $dark;
    padding: 2vh;
    ul {
        text-align: left;
        line-height: 3vh;
    }

    ul li {
        list-style-type:georgian;
        counter-increment: item;
    }
}

.p5-tb {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 4vh;
    line-height: 4.5vh;
    text-transform: lowercase;
    
    color: white;
}

// white header
.p5-white {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 3.5vh;
    line-height: 4.5vh;
    text-transform: lowercase;
    text-align: center;

    color: white;
}
// Background

div#background {
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    padding-top: 12vh;
}

#tb-logo {
    width: 10vh;
    height: auto;
    padding-left: 45%;
}

div.header-tags {
    display: flex;
    flex-direction: column;
}
// Problem

div#problem {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100vh;
    div.p5 {
        padding: 20px;
        position: sticky;
        top: 0px;
    }
}

.fixedbgd-tb {
    position: absolute;
    background: url("../../assets/projects/TradeBooker/bbgbgd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 500vh;
    z-index: -1;
}

.pain-point-section-tb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    div {
        flex-grow: 1;
    }
    .pain-points {
        display: flex;
        flex-direction: column;
        row-gap: 2vh;
    }
}

// Solution
div#solution {
    display: flex;
    flex-direction: column;
    row-gap: 10vh;
    align-content: center;
    justify-content: center;
    height: 100vh;
}

// Application

.application {
    display: flex;
    flex-direction: column;
    row-gap: 10vh;
    div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        img {
            max-width: 70%;
            display: block;
            margin: auto;
            flex-grow: 1;
            width: 1;
        }
        div.gray-gradient-bg {
            flex-grow: 1;
            margin: 20px;
            div {
                margin: 20px;
            }
        }
    }
}
// Prototype

div#half-circle-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.half-circle {
    width: 50px;
    height: 100px;
    border-radius: 0 50px 50px 0px; /* for the left half circle */
    background-color: $dark; /* Example background color */
    position: relative;
    overflow: hidden;
  }

  .half-circle-right {
    width: 50px;
    height: 100px;
    border-radius: 50px 0px 0px 50px; /* for the left half circle */
    background-color: $dark; /* Example background color */
    position: relative;
    overflow: hidden;
  }
// Design
// Refinement
// Result
.impact-points {
    display: flex;
    flex-direction: column;
    width: 70%;
    .impact-p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 3vh;
        line-height: 4.5vh;
        text-transform: lowercase;
        
        color: $dark;
        padding: 10px;
        border-radius: 10px;
    }
}

// Summary
div#summary {
    display: flex;
    flex-direction: column;
    row-gap: 10vh;
    align-content: center;
    justify-content: center;
    height: 100vh;
    div {
        color: white;
    }
}
// Reflection