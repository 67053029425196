$dark: #1E1E1E;

.entryscreen-container {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: $dark;
}

/* Fade-out animation */
.fade-out {
    animation: fadeOutAnimation 1s ease-out forwards;
  }
  
  @keyframes fadeOutAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
/* White fade-out overlay */
.fade-out-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2; /* Ensure it's above other content */
    animation: fadeOutOverlayAnimation 1s ease-out forwards;
}

@keyframes fadeOutOverlayAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}