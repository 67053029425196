.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
}

.gallery-container > h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    text-align: center;
    width: 100%;
}